import { Template } from '../Template/Template'
//import { TwistingLogo } from '../TwistingLogo/TwistingLogo'
import './App.css'

export function App() {
    return (
        <div className="App">
            {/* <TwistingLogo /> */}
            <Template />
        </div>
    )
}
